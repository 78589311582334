export const styles = {
  wrapper: {
    paddingTop: '8px',
  },
  divider: {
    width: '100%',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
    borderWidth: '1px',
    marginTop: '16px',
  },
  orderRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '12px 0 12px 0',
    cursor: 'pointer',
    '&:hover': {
      bgcolor: 'custom.blueLight3',
    },
  },
  circle: {
    position: 'relative',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    minWidth: '24px',
    minHeight: '24px',
    marginRight: '8px',
  },
  subtitle: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: 'custom.black',
  },
  name: {
    fontSize: '14px',
    lineHeight: '16px',
    fontWeight: 400,
    color: 'custom.graphite',
  },
  priorityBlock: {
    position: 'absolute',
    top: -12,
    right: -12,
  },
  addressLine2: {
    color: 'custom.black',
    fontSize: '16px',
    lineHeight: '20px',
  },
};
