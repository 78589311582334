import { ERRAND_SERVICE_OPTIONS } from 'constants/editOrderOptions';
import { sortBy } from 'lodash';
import { t } from 'ttag';
import {
  Errand,
  ErrandDataProofTypes,
  ErrandProofTypes,
  Shipment,
  ShipmentStatuses,
  TerminalErrand,
} from 'types/api';
import {
  CommonOrderData,
  Order,
  OrderAddressTypes,
  OrderStatuses,
  OrderTime,
  OrderTypes,
} from 'types/orders';

import {
  dateStringToNumber,
  formatDateStringToHHmm,
  getTheLargerOfTheTwoDates,
  twoDateStringsDifference,
} from './timeUtils';

interface Orders {
  completedOrders: Order[] | [];
  uncompletedOrders: Order[] | [];
}

interface TypedErrand extends Errand {
  type: OrderTypes;
  status: OrderStatuses;
  shipmentStatus: ShipmentStatuses;
}

const initialOrders: Orders = {
  completedOrders: [],
  uncompletedOrders: [],
};

export const getOrderStatus = (shipment: Shipment): OrderStatuses => {
  switch (getOldShipmentStatus(shipment)) {
    case ShipmentStatuses.PICKED:
    case ShipmentStatuses.DROPPED:
      return OrderStatuses.DONE;
    case ShipmentStatuses.FAILED_PICK:
    case ShipmentStatuses.FAILED_DROP:
      return OrderStatuses.FAILED;
    case ShipmentStatuses.DECLINED:
      return OrderStatuses.DECLINED;
    default:
      return OrderStatuses.PENDING;
  }
};

const getOrderProofTypeLabel = (proofType: ErrandProofTypes | undefined) => {
  switch (proofType) {
    case 'damage':
      return t`Damaged`;
    case 'can_not_find_location':
      return t`Can not find the address / doorway`;
    case 'can_not_enter_the_building':
      return t`Can not enter the building`;
    case 'can_not_find_the_door_of_the_address':
      return t`Can not find recipient name / front door on the address`;
    case 'nobody_home':
      return t`Nobody answering the front door`;
    case 'receiver_is_home_but_delivery_failed':
      return t`Recipient is home but delivery failed`;
    case 'another_reason':
      return t`Other reason`;
    case 'other_reason':
      return t`Other reason`;
    case 'no_intercom':
      return t`No intercom`;
    case 'no_door_code':
      return t`No door code`;
    case 'no_phone_answer':
      return t`No phone answer`;
    case 'no_intercom_answer':
      return t`No intercom answer`;
    case 'no_id_verified':
      return t`No id verified`;
    case 'no_age_verified':
      return t`No age verified`;
    case 'not_appropriate':
      return t`Not appropriate`;
    case 'invalid_door_code':
      return t`Invalid door code`;
    case 'not_home':
      return t`Not at home`;
    case 'incorrect_address':
      return t`Incorrect address`;
    case 'damaged_parcel':
      return t`Damaged parcel`;
    case 'skipped_call':
      return t`Skipped call`;
    case 'skipped_chat':
      return t`Skipped chat`;
    case 'in_front_of_door':
      return t`In front of door`;
    case 'signature':
      return t`Not signed`;
    case 'identification_control':
      return t`Not identified`;
    case 'smart_lock':
      return t`Smart lock error`;
    case 'smartlock_inside_the_box':
      return t`Inside the box error`;
    case 'smartlock_glue':
      return t`GLUE delivery was failed`;
    case 'smartlock_qlocx':
      return t`QLOCX delivery was failed`;
    case 'smartlock_iboxen':
      return t`IBOXEN delivery was failed`;
    case 'bankid':
      return t`BankID delivery was failed`;
    case 'bankid_strict':
      return t`BankID delivery was failed`;
    case 'smartlock_home':
      return t`Home error`;
    case 'smartlock_boxes':
      return t`Boxes error`;
    case 'driver_sign_off':
      return t`Failed by dispatch`;
    default:
      return proofType;
  }
};

const getOrderData = (
  shipment: Shipment,
  errand: TypedErrand,
  iBoxenPickupFailPOD?: string,
  isDrop = false,
): Order => {
  const address = errand?.address;
  const data = errand?.data;
  const booking = shipment?.booking;
  const bookingData = booking?.data;
  const { pickup_errand: pickErrand, delivery_errand: deliveryErrand } =
    shipment;
  const date = new Date();

  const shipmentStatus = errand.shipmentStatus;
  const status = iBoxenPickupFailPOD ? OrderStatuses.FAILED : errand.status;
  const type = errand.type;
  const addressType =
    errand?.service !== 'Courier' &&
    !(errand?.service === 'Distribution' && errand.type === OrderTypes.PICK)
      ? OrderAddressTypes.TERMINAL
      : OrderAddressTypes.COURIER;

  const isReducePod =
    addressType === OrderAddressTypes.TERMINAL && type === OrderTypes.PICK;

  let proofDate =
    Date.parse(iBoxenPickupFailPOD || errand?.proof?.date || '') || 0;

  if (isReducePod && proofDate) {
    proofDate = proofDate - 10000;
  }

  if (iBoxenPickupFailPOD) {
    proofDate = proofDate + 1000;
  }

  const commonOrderData: CommonOrderData = {
    id: errand.id,
    shipmentId: shipment.id,
    bookingId: booking.id,
    type,
    status,
    shipmentStatus,
    bookingData: bookingData || null,
    isHidden: false,
    isNew: getOldShipmentStatus(shipment) === ShipmentStatuses.ASSIGNED,
    isEditable: true,
    isCollapsible: false,
    orderNumber: errand.order_number,
    bookingNumber: booking.data?.number || null,
    freightBill: booking?.freight_bill,
    subtitle: data.company_name || data.name,
    isTerminalReturn: shipment.delivery_errand.service === 'Terminal delivery',
    isManuallyCreated: !!shipment.delivery_errand?.data?.manually_created,
    address: {
      ...address,
      type: addressType,
      title: `${address.street}, ${address.street_number}`,
      subtitle: `${address?.postal_code}, ${address?.city}`,
      addressLine2: address?.address_line_2 ? address.address_line_2 : '',
    },
    time: {
      difference: errand?.eta
        ? twoDateStringsDifference(errand.latest_date, errand?.eta)
        : dateStringToNumber(date.toString()),
      eta: errand?.eta ? formatDateStringToHHmm(errand?.eta) : null,
      latest: new Date(errand.latest_date),
      earliest: new Date(errand.earliest_date),
      fullEta: errand?.eta,
    },
    proofDate,
    proofData: errand?.proof,
    data: {
      instruction: data.instruction,
      phoneNumber: data.phone,
      recipientName: data.name,
      intercom: data.intercom,
      enter_code: data.enter_code,
      knock_on_the_door: data.knock_on_the_door,
      quiet_delivery: data?.quiet_delivery || null,
      quiet_pickup: data?.quiet_pickup || null,
      use_doorbell: data?.use_doorbell || null,
      elevator: data.elevator,
      floor: data.floor,
      packaging_return: data?.packaging_return,
      proofType: data?.possible_proof_types?.[0] || null,
      ageControl: (data?.age_control as number) || null,
      isManualCreated: data?.manually_created || null,
    },
    additionsData: {
      adr: bookingData?.adr || null,
      tempered_transport: bookingData?.tempered_transport || null,
      on_call_courier: data.on_call_courier,
      time_slotted_lorry: data.time_slotted_lorry,
      small_addition_package_charge:
        bookingData?.small_addition_package_charge || null,
      big_addition_package_charge:
        bookingData?.big_addition_package_charge || null,
      extra_pallet_space: bookingData?.extra_pallet_space || null,
      flatbed_meter: bookingData?.flatbed_meter || null,
      pickup_waiting_time: pickErrand.data.waiting_time,
      delivery_waiting_time: deliveryErrand.data.waiting_time,
      loading_time: pickErrand.data.loading_time,
      unloading_time: deliveryErrand.data.unloading_time,
      installation: bookingData?.installation || null,
      ferry_addition_cost: data.ferry_addition_cost,
      new_pickup_address: pickErrand.data.new_address,
      new_delivery_address: deliveryErrand.data.new_address,
    },
    deliveryData: {
      general_parcel_type: bookingData?.general_parcel_type || null,
      goods_marking: bookingData?.goods_marking || null,
      goods_type: bookingData?.goods_type || null,
      pallet_location: bookingData?.pallet_location || null,
    },
    packages: booking?.packages,
  };

  const order: Order = { ...commonOrderData };

  if (isDrop) {
    order.dropTerminalId = shipment.delivery_errand.data?.terminal_id;
    order.pickAddress = {
      ...shipment.pickup_errand.address,
      title: `${shipment.pickup_errand.address.street}, ${shipment.pickup_errand.address.street_number}`,
      subtitle: `${shipment.pickup_errand.address?.postal_code}, ${shipment.pickup_errand.address?.city}`,
      addressLine2: shipment.pickup_errand.address?.address_line_2
        ? shipment.pickup_errand.address?.address_line_2
        : '',
    };
  } else {
    order.pickTerminalId = shipment.pickup_errand.data?.terminal_id;
  }

  if (order.type === OrderTypes.PICK) {
    if (deliveryErrand?.data?.instruction) {
      order.deliveryInstruction = deliveryErrand?.data?.instruction;
    }
  }

  if (status === OrderStatuses.DONE) {
    order.isEditable = false;
  }

  if (status === OrderStatuses.FAILED) {
    order.isEditable = false;
  }

  // Delivered
  if (status === OrderStatuses.DONE) {
    order.time = {
      difference: errand?.latest_date
        ? twoDateStringsDifference(
            errand?.latest_date,
            errand?.proof?.date as string,
          )
        : dateStringToNumber(errand?.proof?.date as string),
      pod: formatDateStringToHHmm(errand?.proof?.date),
    };
  }

  if (status === OrderStatuses.FAILED) {
    order.time = {
      difference: errand?.latest_date
        ? twoDateStringsDifference(
            errand?.latest_date,
            iBoxenPickupFailPOD || (errand?.proof?.date as string),
          )
        : dateStringToNumber(errand?.proof?.date as string),
      pod: formatDateStringToHHmm(iBoxenPickupFailPOD || errand?.proof?.date),
    };
    order.hint = getOrderProofTypeLabel(errand.proof?.proof_type);
  }

  if (addressType === OrderAddressTypes.COURIER) {
    order.dataTitle =
      ERRAND_SERVICE_OPTIONS.find((option) => option.value === data?.title)
        ?.label ||
      data?.title ||
      null;
  }

  if (addressType === OrderAddressTypes.TERMINAL && type === OrderTypes.PICK) {
    order.subtitle = data.terminal_name;
    order.isEditable = false;
    order.additionsData = null;
    order.packages = null;
    order.bookingNumber = null;
  }

  if (
    addressType === OrderAddressTypes.TERMINAL &&
    type === OrderTypes.DROP &&
    order?.dropTerminalId
  ) {
    order.subtitle = data.terminal_name;
    order.isEditable = false;
    order.additionsData = null;
    order.packages = null;
    order.bookingNumber = null;
  }

  return order;
};

export const setNewShipmentStatus = (status: ShipmentStatuses) => {
  if (!status) {
    return '';
  }
  if (
    status === ShipmentStatuses.UNASSIGNED ||
    status === ShipmentStatuses.ASSIGNED
  ) {
    return ShipmentStatuses.NEW;
  }

  return status;
};

export const getOldShipmentStatus = (shipment: Shipment): ShipmentStatuses => {
  if (shipment.status === ShipmentStatuses.NEW && shipment.work_shift_id) {
    return ShipmentStatuses.ASSIGNED;
  }

  if (shipment.status === ShipmentStatuses.NEW && !shipment.work_shift_id) {
    return ShipmentStatuses.UNASSIGNED;
  }

  if (
    shipment.status === ShipmentStatuses.DECLINED &&
    !shipment.work_shift_id
  ) {
    return ShipmentStatuses.UNASSIGNED;
  }

  return shipment.status;
};

export const getOrdersFromShipment = (shipment: Shipment): Order[] | null => {
  const { pickup_errand: pickErrand, delivery_errand: dropErrand } = shipment;

  const errands: { pick: TypedErrand; drop: TypedErrand } = {
    pick: {
      ...pickErrand,
      type: OrderTypes.PICK,
      status: OrderStatuses.PENDING,
      shipmentStatus: getOldShipmentStatus(shipment),
    },
    drop: {
      ...dropErrand,
      type: OrderTypes.DROP,
      status: OrderStatuses.PENDING,
      shipmentStatus: getOldShipmentStatus(shipment),
    },
  };

  switch (getOldShipmentStatus(shipment)) {
    case 'Picked Up':
      errands.pick.status = OrderStatuses.DONE;
      errands.drop.status = OrderStatuses.PENDING;
      break;
    case 'Delivered':
      errands.pick.status = OrderStatuses.DONE;
      errands.drop.status = OrderStatuses.DONE;
      break;
    case 'Failed Pick Up':
      errands.pick.status = OrderStatuses.FAILED;
      break;
    case 'Failed Delivery':
      errands.drop.status = OrderStatuses.FAILED;
      errands.pick.status = OrderStatuses.DONE;
      break;
    case 'Assigned':
      errands.pick.status = OrderStatuses.DECLINED;
      errands.drop.status = OrderStatuses.PENDING;
      break;
    case 'Confirmed':
      errands.pick.status = OrderStatuses.PENDING;
      errands.drop.status = OrderStatuses.PENDING;
      break;
    case 'Unassigned':
      errands.pick.status = OrderStatuses.PENDING;
      errands.drop.status = OrderStatuses.PENDING;
      break;
    default:
      return null;
  }

  let iBoxenPickupPOD;
  if (
    pickErrand?.data?.possible_proof_types?.[0] ===
      ErrandDataProofTypes.smartLockIboxen &&
    shipment.status === ShipmentStatuses.FAILED_PICK &&
    pickErrand.service === 'Courier'
  ) {
    iBoxenPickupPOD = pickErrand.proof?.date;
  }
  const dropOrder =
    dropErrand && getOrderData(shipment, errands.drop, iBoxenPickupPOD, true);
  const pickOrder: Order = pickErrand && {
    ...getOrderData(shipment, errands.pick),
    contraryOrder: dropOrder,
  };
  // TODO: Fix types for service

  if (dropErrand === null) {
    return [pickOrder];
  } else if (pickErrand === null) {
    return [dropOrder];
  }
  return [pickOrder, dropOrder];
};

const getOrdersList = (shipmentsList: Shipment[]) => {
  return shipmentsList.reduce((acc: Order[], shipment) => {
    const orders = getOrdersFromShipment(shipment);

    if (!orders) {
      return acc;
    }
    return [...acc, ...orders];
  }, []);
};

const groupSortedOrdersList = (
  ordersList: Order[],
  errandsList: TerminalErrand[] = [],
) => {
  let orderGroup: Order | null = null;
  let previousOrderGroup: Order | null = null;
  const groupedList: Order[] = [];

  for (let i = 0; i < ordersList.length; i++) {
    const order = ordersList[i];
    const nextOrder = ordersList[i + 1];
    const previousOrder = ordersList[i - 1];

    if (order.address.type === OrderAddressTypes.COURIER) {
      groupedList.push(order);
      continue;
    }

    const isOrderEqualToPrevious =
      order?.address.id === previousOrder?.address.id &&
      order.type === previousOrder?.type &&
      order.address.type === previousOrder?.address.type;

    const isOrderEqualToPreviousIBoxen =
      order?.address.id === previousOrder?.address.id &&
      order.type === previousOrder?.type &&
      order.address.type === previousOrder?.address.type &&
      previousOrder?.data?.proofType === ErrandDataProofTypes.smartLockIboxen;

    const isFailOrSuccess =
      order?.status === OrderStatuses.DONE ||
      order?.status === OrderStatuses.FAILED;

    const isNextFailOrSuccess =
      nextOrder?.status === OrderStatuses.DONE ||
      nextOrder?.status === OrderStatuses.FAILED;

    const isPreviousFailOrSuccess =
      previousOrder?.status === OrderStatuses.DONE ||
      previousOrder?.status === OrderStatuses.FAILED;

    const isOrderEqualToNext =
      order?.address.id === nextOrder?.address.id &&
      order?.type === nextOrder?.type &&
      order?.address.type === nextOrder?.address.type;

    const isOrderEqualToNextIBoxen =
      order?.address.id === nextOrder?.address.id &&
      order?.type === nextOrder?.type &&
      order?.address.type === nextOrder?.address.type &&
      nextOrder?.data?.proofType === ErrandDataProofTypes.smartLockIboxen;

    const isDropOrderType = order.type === OrderTypes.DROP;
    const isTerminalPickOrder =
      order?.type === OrderTypes.PICK &&
      order?.address.type === OrderAddressTypes.TERMINAL;
    const isTerminalDropOrder =
      isDropOrderType && order?.address?.type === OrderAddressTypes.TERMINAL;

    const isIBoxenDelivery =
      order?.data?.proofType === ErrandDataProofTypes.smartLockIboxen &&
      isDropOrderType;

    const dropOptionOrder = isDropOrderType
      ? order
      : (order?.contraryOrder as Order);
    const subtitle = isDropOrderType ? t`Multiple delivery` : order.subtitle;

    if (!nextOrder && !orderGroup) {
      groupedList.push(order);
      break;
    }

    const nextEqualWithoutIBoxen =
      isOrderEqualToNext &&
      !isNextFailOrSuccess &&
      !isIBoxenDelivery &&
      !isOrderEqualToNextIBoxen &&
      !nextOrder?.isTerminalReturn;

    const previousEqualWithoutIBoxen =
      isOrderEqualToPrevious &&
      !isIBoxenDelivery &&
      !isOrderEqualToPreviousIBoxen &&
      !isPreviousFailOrSuccess &&
      !previousOrder?.isTerminalReturn;

    const nextEqualDeliveryIBoxen =
      isOrderEqualToNextIBoxen && !isNextFailOrSuccess && isIBoxenDelivery;

    const previousEqualDeliveryIBoxen =
      isOrderEqualToPreviousIBoxen &&
      isIBoxenDelivery &&
      !isPreviousFailOrSuccess;

    if (
      (nextEqualWithoutIBoxen ||
        previousEqualWithoutIBoxen ||
        nextEqualDeliveryIBoxen ||
        previousEqualDeliveryIBoxen) &&
      !isFailOrSuccess &&
      !isTerminalPickOrder
    ) {
      previousOrderGroup = null;
      orderGroup = orderGroup
        ? {
            ...(orderGroup as Order),
            dropOptions: [...(orderGroup?.dropOptions || []), dropOptionOrder],
            isEditable: false,
            freightBill: undefined,
            time: {
              ...(orderGroup.time as OrderTime),
              be_terminal_latest: getTheLargerOfTheTwoDates(
                orderGroup.time?.be_terminal_latest || null,
                order.time?.earliest || null,
              ),
              leave_terminal_latest: getTheLargerOfTheTwoDates(
                orderGroup.time?.leave_terminal_latest || null,
                order.time?.latest || null,
              ),
            },
          }
        : {
            ...order,
            dropOptions: [dropOptionOrder],
            id: `group-${order.id}`,
            isCollapsible: isDropOrderType,
            isEditable: false,
            freightBill: undefined,
            time: {
              ...order.time,
              be_terminal_latest: order.time?.earliest,
              leave_terminal_latest: order.time?.latest,
            },
            subtitle,
          };
      groupedList.push({ ...order, isHidden: true });
    } else if (isTerminalDropOrder) {
      order.time = {
        ...order.time,
        be_terminal_latest:
          // @ts-ignore
          previousOrderGroup?.time?.be_terminal_latest || null,
        leave_terminal_latest:
          // @ts-ignore
          previousOrderGroup?.time?.leave_terminal_latest || null,
      };
      groupedList.push(order);
    } else {
      groupedList.push(order);
    }

    if (
      (!isOrderEqualToNext && !isIBoxenDelivery && orderGroup) ||
      (isOrderEqualToNextIBoxen && !isIBoxenDelivery && orderGroup) ||
      (!isOrderEqualToNextIBoxen && isIBoxenDelivery && orderGroup)
      // ||
      // (isTerminalPickOrder && !nextShipmentStatusMatch && orderGroup)
    ) {
      groupedList.push(orderGroup);
      // previousOrderGroup = orderGroup;
      orderGroup = null;
    }
  }

  let ordersIdsForSetHidden: string[] = [];

  const terminalTasks = errandsList?.reduce((accum: Order[], curr): Order[] => {
    if (curr?.terminal_group_number) {
      const currentTerminalTasks = errandsList.filter(
        (e) =>
          e?._meta?.terminal_group_number &&
          curr?.terminal_group_number &&
          e?._meta?.terminal_group_number === curr?.terminal_group_number,
      );

      const nearestPickupTask = currentTerminalTasks?.[0];

      // const currTermTasksWithLatestTime = currentTerminalTasks.map(t => ({
      //   ...t,
      //   latestDateNumber: Date.parse(t?.latest_date || ''),
      // }));

      // const sortedCurrTermTasksWithLatestTime = orderBy(
      //   currTermTasksWithLatestTime,
      //   'latestDateNumber',
      //   'desc',
      // );

      if (nearestPickupTask?.status === ShipmentStatuses.DECLINED) {
        return accum;
      }

      const currentTerminalTasksIds = currentTerminalTasks.map(({ id }) => id);
      ordersIdsForSetHidden = [
        ...ordersIdsForSetHidden,
        ...(currentTerminalTasksIds as string[]),
      ];

      let dropOptionOrders: Order[] = [];
      const filteredGroupedList = groupedList.filter((g) =>
        currentTerminalTasksIds.includes(g.id),
      );

      if (filteredGroupedList.length) {
        dropOptionOrders = filteredGroupedList.reduce(
          (accum: Order[], curr) => {
            if (curr?.contraryOrder) {
              return [...accum, curr.contraryOrder];
            }

            return accum;
          },
          [],
        );
      }

      const order = groupedList.find((g) => g.id === nearestPickupTask?.id);

      if (!order) {
        return accum;
      }

      return [
        ...accum,
        {
          ...order,
          dropOptions: dropOptionOrders,
          id: `terminal-${curr.terminal_group_number}-${curr.terminal_id}`,
          isCollapsible: false,
          isEditable: false,
          freightBill: undefined,
          time: {
            ...order.time,
            be_terminal_latest: order.time?.earliest,
            leave_terminal_latest: order.time?.latest,
          },
        },
      ];
    }

    return accum;
  }, []);

  const groupedListWithTerminalPickupOrders = [
    ...terminalTasks,
    ...groupedList.map((g) =>
      ordersIdsForSetHidden.includes(g.id) ? { ...g, isHidden: true } : g,
    ),
  ];

  const groupedTerminalReturnedDoneOrders = [
    ...groupedListWithTerminalPickupOrders,
  ].reduce((accum: Order[], curr: Order): Order[] => {
    const indexAccumWithTheSameId = accum.findIndex(
      (i) => i?.dropTerminalId && i?.dropTerminalId === curr?.dropTerminalId,
    );
    if (
      curr?.dropTerminalId &&
      curr.shipmentStatus === ShipmentStatuses.DROPPED &&
      curr.isTerminalReturn &&
      indexAccumWithTheSameId === -1
    ) {
      const insertedOrder = {
        ...curr,
        dropOptions: [curr],
        id: `group-${curr.id}`,
        isCollapsible: false,
        isEditable: false,
        freightBill: undefined,
      };

      return [...accum, insertedOrder];
    } else if (
      curr?.dropTerminalId &&
      curr.shipmentStatus === ShipmentStatuses.DROPPED &&
      curr.isTerminalReturn &&
      indexAccumWithTheSameId >= 0
    ) {
      accum[indexAccumWithTheSameId].dropOptions = [
        ...(accum[indexAccumWithTheSameId].dropOptions as Order[]),
        curr,
      ];

      return accum;
    }

    return accum;
  }, []);

  const groupedTerminalReturnedPendingOrders = [
    ...groupedListWithTerminalPickupOrders,
  ].reduce((accum: Order[], curr: Order): Order[] => {
    const indexAccumWithTheSameId = accum.findIndex(
      (i) => i?.dropTerminalId && i?.dropTerminalId === curr?.dropTerminalId,
    );
    if (
      curr?.dropTerminalId &&
      curr.shipmentStatus !== ShipmentStatuses.DROPPED &&
      curr.isTerminalReturn &&
      indexAccumWithTheSameId === -1
    ) {
      const insertedOrder = {
        ...curr,
        dropOptions: [curr],
        id: `group-${curr.id}`,
        isCollapsible: true,
        isEditable: false,
        freightBill: undefined,
      };

      return [...accum, insertedOrder];
    } else if (
      curr?.dropTerminalId &&
      curr.shipmentStatus !== ShipmentStatuses.DROPPED &&
      curr.isTerminalReturn &&
      indexAccumWithTheSameId >= 0
    ) {
      accum[indexAccumWithTheSameId].dropOptions = [
        ...(accum[indexAccumWithTheSameId].dropOptions as Order[]),
        curr,
      ];

      return accum;
    }

    return accum;
  }, []);

  const groupedListWithoutTerminalReturn = groupedListWithTerminalPickupOrders
    // .filter(
    // (g) => !g.isTerminalReturn && !g?.dropTerminalId,
    // )
    .map((curr) => {
      if (
        curr?.dropTerminalId &&
        curr.shipmentStatus === ShipmentStatuses.DROPPED &&
        curr.isTerminalReturn
      ) {
        return {
          ...curr,
          isHidden: true,
        };
      }

      if (
        curr?.dropTerminalId &&
        curr.shipmentStatus !== ShipmentStatuses.DROPPED &&
        curr.isTerminalReturn &&
        !curr?.dropOptions
      ) {
        return {
          ...curr,
          isHidden: true,
        };
      }

      return curr;
    });

  return [
    ...groupedListWithoutTerminalReturn,
    ...groupedTerminalReturnedDoneOrders,
    ...groupedTerminalReturnedPendingOrders,
  ];
};

export const getOrders = (
  shipmentsList: Shipment[],
  errandsList: TerminalErrand[] = [],
  isUnassigned = false,
) => {
  const ordersList = getOrdersList(shipmentsList);

  if (isUnassigned) {
    return ordersList;
  }

  const groupedOrders = groupSortedOrdersList(
    sortBy(ordersList, 'orderNumber'),
    errandsList,
  );

  const { completedOrders, uncompletedOrders } = groupedOrders.reduce(
    (acc: Orders, order: Order) => {
      if (
        (order.status === OrderStatuses.FAILED ||
          order.status === OrderStatuses.DONE) &&
        !(order.isManuallyCreated && order.type === OrderTypes.PICK)
      ) {
        return { ...acc, completedOrders: [...acc.completedOrders, order] };
      } else if (
        (order.status === OrderStatuses.FAILED ||
          order.status === OrderStatuses.DONE) &&
        order.isTerminalReturn
      ) {
        return acc;
      }
      return { ...acc, uncompletedOrders: [...acc.uncompletedOrders, order] };
    },
    initialOrders,
  );

  const sortedUncompletedOrders = sortBy(uncompletedOrders, 'orderNumber');
  const sortedCompletedOrders = sortBy(completedOrders, 'proofDate');
  const filteredSortedUncompletedOrders =
    sortedUncompletedOrders?.filter(({ isHidden }) => !isHidden) || [];
  if (
    filteredSortedUncompletedOrders?.length &&
    filteredSortedUncompletedOrders[0].shipmentStatus !==
      ShipmentStatuses.UNASSIGNED
  ) {
    filteredSortedUncompletedOrders[0].isCurrent = true;
  }

  const sortedOrdersList = [
    ...sortedCompletedOrders,
    ...sortedUncompletedOrders,
  ];

  return sortedOrdersList;
};
