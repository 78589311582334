export const styles = {
  wrapper: {
    // paddingTop: '8px',
    // borderRadius: '12px',
    // maxWidth: '403px',
  },

  header: {
    width: '403px',
    height: '64px',
    bgcolor: 'custom.greyLight',
    padding: '0 12px 0 24px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: '12px',
    borderTopRightRadius: '12px',
  },

  resourceInfo: {
    display: 'flex',
    flexDirection: 'row',
  },

  divider: {
    width: '100%',
    borderBottomStyle: 'solid',
    borderBottomColor: 'custom.greyLight',
    borderBottomWidth: '1px',
    // marginTop: '16px',
  },

  divider24: {
    width: '95%',
    borderBottomStyle: 'solid',
    borderBottomColor: 'custom.greyLight',
    borderBottomWidth: '1px',
    // paddingLeft: '24px',
    alignSelf: 'flex-end',
    '&:hover': {
      width: '95%',
      borderBottomStyle: 'solid',
      borderBottomColor: 'custom.greyLight',
      borderBottomWidth: '1px',
      // paddingLeft: '24px',
      alignSelf: 'flex-end',
    },
  },

  ordersList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '24px 0 0 0',
  },
  buttonsBlock: {
    padding: '24px',
  },
  button: {
    width: '100%',
  },
  locationItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  locationIcon: {
    color: 'custom.red',
  },
  locationBlock: {
    padding: '24px 24px 0 24px',
  },
  errandItemBlock: {
    // padding: '0 15px',
    cursor: 'pointer',
    '&:hover': {
      bgcolor: 'custom.greyLightBlue',
    },
  },

  errandBlockContent: {
    ml: '12px',
    mt: '-1px',
    p: '10px 0',

    // borderTop: '1px solid',
    // borderColor: 'custom.greyLight',
  },

  addressLine2: {
    color: 'custom.black',
    fontSize: '16px',
    lineHeight: '20px',
  },
};
