import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { APP_ROUTES } from 'constants/common';
import { FormikHelpers, FormikValues, useFormik } from 'formik';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import { debounce, isEmpty, sortBy } from 'lodash';
import {
  useAddAddressMutation,
  useAddTerminalMutation,
  useGetAddressByStreetAndCityNamesQuery,
  useGetStreetsGroupedByCityQuery,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setToast } from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import { ErrorDetailArrayItem, WrappedErrorApi } from 'types/api';
import {
  getMaxLengthValidationError,
  getRequiredFieldValidationError,
} from 'utils/textLabelUtils';
import { createUniqueId } from 'utils/uniqIdUtils';
import * as yup from 'yup';

import { Section } from 'components/ui/Section';

import { styles } from './styles';

const validationSchema = yup.object({
  terminalSearch: yup.string().nullable().min(4),
  terminalStreet: yup
    .string()
    .nullable()
    .min(3)
    .required(getRequiredFieldValidationError('Street name')),
  terminalAddressId: yup
    .string()
    .nullable()
    .required(getRequiredFieldValidationError('Address ID')),
  terminalCity: yup
    .string()
    .nullable()
    .required(getRequiredFieldValidationError('City name')),
  terminalCountry: yup
    .string()
    .nullable()
    .required(getRequiredFieldValidationError('Country name')),
  terminalLatitude: yup
    .number()
    .nullable()
    .required(getRequiredFieldValidationError('Latitude')),
  terminalLongitude: yup
    .number()
    .nullable()
    .required(getRequiredFieldValidationError('Longitude')),
  terminalPostalCode: yup
    .string()
    .nullable()
    .required(getRequiredFieldValidationError('Postal code')),
  terminalState: yup.string().nullable(),
  addressLine2: yup
    .string()
    .nullable()
    .max(100, getMaxLengthValidationError('Terminal name', 100)),
  // .required(getRequiredFieldValidationError('State name')),
  terminalStreetNumber: yup
    .string()
    .nullable()
    .required(getRequiredFieldValidationError('Street number')),
  terminalName: yup
    .string()
    .nullable()
    .max(50, getMaxLengthValidationError('Terminal name', 50))
    .required(getRequiredFieldValidationError('Terminal name')),
  terminalCode: yup
    .string()
    .nullable()
    .max(30, getMaxLengthValidationError('Terminal code', 30))
    .required(getRequiredFieldValidationError('Terminal code')),
  terminalPhone: yup
    .string()
    .nullable()
    .max(30, getMaxLengthValidationError('Phone number', 30))
    .required(getRequiredFieldValidationError('Phone number')),
});

export interface AddTerminalFormValues extends FormikValues {
  terminalSearch: string;
  terminalStreet: string;
  terminalStreetNumber: string;
  terminalCountry: string;
  terminalState: string;
  terminalCity: string;
  terminalPostalCode: string;
  terminalLatitude: number;
  terminalLongitude: number;
  terminalAddressId: string;
  terminalName: string;
  terminalCode: string;
  terminalPhone: string;
  addressLine2: string;
}

export const AddTerminalScreen: FC = () => {
  const [addTerminal, { isError, error }] = useAddTerminalMutation();
  const dispatch = useAppDispatch();

  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const [addAddress] = useAddAddressMutation();

  const submitFormHandler = useCallback(
    async (
      {
        terminalName: hub_name,
        terminalCode: hub_code,
        terminalAddressId,
        terminalPhone: hub_phone,
        terminalStreet: street,
        terminalStreetNumber: street_number,
        terminalCountry: country,
        terminalState: state,
        terminalCity: city,
        terminalPostalCode: postal_code,
        terminalLatitude: latitude,
        terminalLongitude: longitude,
        addressLine2: address_line_2,
      }: AddTerminalFormValues,
      helpers: FormikHelpers<AddTerminalFormValues>,
    ) => {
      helpers.setSubmitting(true);

      let address_id = terminalAddressId;
      const addressResponse = await addAddress({
        street,
        city,
        state,
        country,
        street_number,
        postal_code,
        latitude,
        longitude,
        address_line_2,
      }).unwrap();

      if (addressResponse?.id) {
        address_id = addressResponse.id;
      }

      const res = await addTerminal({
        hub_name,
        hub_code,
        address_id,
        data: {
          hub_phone,
        },
      });

      await helpers.setSubmitting(false);

      if ((res as WrappedErrorApi)?.error) {
        dispatch(
          setToast({
            message:
              (
                (res as WrappedErrorApi)?.error?.data
                  ?.detail?.[0] as ErrorDetailArrayItem
              )?.msg ||
              ((res as WrappedErrorApi)?.error?.data?.detail as string) ||
              'Something wrong in Add Terminal!',
            severity: 'error',
          }),
        );
        return;
      }

      dispatch(
        setToast({
          message: t`You have successfully saved terminal`,
          severity: 'success',
        }),
      );
      formik.resetForm();
      navigateWithQueryParams(APP_ROUTES.root);
    },
    [isError, addTerminal, error],
  );

  const formik = useFormik<AddTerminalFormValues>({
    initialValues: {
      terminalSearch: '',
      terminalStreet: '',
      terminalStreetNumber: '',
      terminalCountry: '',
      terminalState: '',
      terminalCity: '',
      terminalPostalCode: '',
      terminalLatitude: 0,
      terminalLongitude: 0,
      terminalName: '',
      terminalCode: '',
      terminalPhone: '',
      terminalAddressId: '',
      addressLine2: '',
    },
    validationSchema,
    onSubmit: submitFormHandler,
  });

  const {
    values,
    setValues,
    handleChange,
    handleBlur,
    touched,
    errors,
    isSubmitting,
  } = formik;

  const [inputValue, setInputValue] = useState('');
  const [streetCityValue, setStreetCityValue] = useState<any>(null);
  const [addressValue, setAddressValue] = useState<any>(null);
  const [streetName, setStreetName] = useState(streetCityValue?.street);

  const value = useMemo(() => {
    if (addressValue) {
      return [streetCityValue, addressValue];
    } else if (streetCityValue) {
      return [streetCityValue];
    }
    return [];
  }, [streetCityValue, addressValue]);

  const { street, city } = streetCityValue || {};

  const { data: addressOptions } = useGetAddressByStreetAndCityNamesQuery(
    { street, city },
    {
      skip: !streetName || !city,
      refetchOnMountOrArgChange: true,
    },
  );

  const { data: streetOptions } = useGetStreetsGroupedByCityQuery(streetName, {
    skip: !streetName || city,
    refetchOnMountOrArgChange: true,
  });

  const options = useMemo(() => {
    if (addressValue) {
      return [];
    }
    return sortBy(city ? addressOptions : streetOptions, ['street', 'city']);
  }, [addressOptions, streetOptions, city, addressValue]);

  const getPlaceholder = () => {
    if (addressValue) {
      return '';
    }
    return city ? t`Enter street number` : t`Enter street name`;
  };

  const autocompleteChangeHandler = (_event: any, newValue: any[]) => {
    const { city, street } = newValue[0] || {};
    const newAddressValue = newValue[1] || {};
    const streetNumber = newAddressValue.street_number;

    if (city && street) {
      setStreetCityValue({ street, city });
    } else {
      setStreetCityValue(null);
      setStreetName('');
    }

    if (streetNumber) {
      setAddressValue({ streetNumber });
      const modifiedValues: any = {};
      (modifiedValues.terminalAddressId = newAddressValue.id || ''),
        (modifiedValues.terminalCity = newAddressValue.city || ''),
        (modifiedValues.terminalCountry = newAddressValue.country || ''),
        (modifiedValues.terminalLatitude = newAddressValue.latitude || 0),
        (modifiedValues.terminalLongitude = newAddressValue.longitude || 0),
        (modifiedValues.terminalPostalCode = newAddressValue.postal_code || ''),
        (modifiedValues.terminalState = newAddressValue.state || ''),
        (modifiedValues.terminalStreet = newAddressValue.street || ''),
        (modifiedValues.addressLine2 = newAddressValue.address_line_2 || ''),
        (modifiedValues.terminalStreetNumber =
          newAddressValue.street_number || ''),
        setValues({
          ...values,
          ...modifiedValues,
        });
    } else {
      setAddressValue(null);
    }
  };

  const searchHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      handleChange(event);
      const { value: newValue } = event.target;

      if (!streetCityValue) {
        setStreetName(newValue);
      }
    },
    [streetCityValue],
  );

  const debouncedSearchHandler = useMemo(
    () => debounce(searchHandler, 100),
    [searchHandler],
  );

  const isValid = useMemo(
    () => formik.isValid && !isEmpty(formik.touched),
    [formik],
  );

  return (
    <FormControl
      component="form"
      onSubmit={formik.handleSubmit}
      autoComplete="off"
      sx={styles.container}
    >
      <Typography sx={styles.title} component="h1">
        {t`Add New Terminal`}
      </Typography>
      <Section
        sx={styles.section}
        label={t`Terminal`}
        labelStyles={styles.subtitle}
      >
        <Grid container columns={12} spacing={2} rowGap={1} padding={2}>
          <Grid item mobile={4}>
            <TextField
              id="terminalName"
              name="terminalName"
              label={t`Terminal name`}
              value={values.terminalName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalName &&
                (Boolean(errors.terminalName) || Boolean(errors.formErrorField))
              }
              helperText={touched.terminalName && errors.terminalName}
              autoComplete="off"
              sx={styles.input}
              fullWidth
              required
            />
          </Grid>
          <Grid item mobile={4}>
            <TextField
              id="terminalCode"
              name="terminalCode"
              label={t`Terminal code`}
              value={values.terminalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalCode &&
                (Boolean(errors.terminalCode) || Boolean(errors.formErrorField))
              }
              helperText={touched.terminalCode && errors.terminalCode}
              autoComplete="off"
              sx={styles.input}
              fullWidth
              required
            />
          </Grid>
          <Grid item mobile={4}>
            <TextField
              id="terminalPhone"
              name="terminalPhone"
              label={t`Phone`}
              value={values.terminalPhone}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalPhone &&
                (Boolean(errors.terminalPhone) ||
                  Boolean(errors.formErrorField))
              }
              helperText={touched.terminalPhone && errors.terminalPhone}
              autoComplete="new-password"
              sx={styles.input}
              fullWidth
              required
            />
          </Grid>

          <Grid item mobile={6}>
            <Autocomplete
              getOptionKey={() => createUniqueId()}
              multiple
              options={options || []}
              value={value}
              onChange={autocompleteChangeHandler}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(value.length === 2 ? '' : newInputValue);
              }}
              getOptionLabel={(option: any) => {
                if (option?.street_number) {
                  return `${option.street_number} ${
                    option?.address_line_2 ? `(${option.address_line_2})` : ''
                  }`;
                } else if (option?.streetNumber) {
                  return option.streetNumber;
                } else if (option?.street && option.city) {
                  return (
                    option?.street_number || `${option.street}, ${option.city}`
                  );
                }
                return option;
              }}
              disablePortal
              fullWidth
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t`Search`}
                  name="search"
                  placeholder={getPlaceholder()}
                  error={!!errors.terminalSearch}
                  helperText={errors.terminalSearch as string}
                  onChange={debouncedSearchHandler}
                  autoComplete="off"
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                />
              )}
            />
          </Grid>
          <Grid item mobile={4}>
            <TextField
              id="terminalStreet"
              name="terminalStreet"
              label={t`Street`}
              value={values.terminalStreet}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalStreet &&
                (Boolean(errors.terminalStreet) ||
                  Boolean(errors.formErrorField))
              }
              helperText={touched.terminalStreet && errors.terminalStreet}
              fullWidth
              sx={styles.input}
              disabled
              required
            />
          </Grid>
          <Grid item mobile={2}>
            <TextField
              id="terminalStreetNumber"
              name="terminalStreetNumber"
              label={t`Street number`}
              value={values.terminalStreetNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalStreetNumber &&
                (Boolean(errors.terminalStreetNumber) ||
                  Boolean(errors.formErrorField))
              }
              helperText={
                touched.terminalStreetNumber && errors.terminalStreetNumber
              }
              sx={styles.input}
              disabled
              fullWidth
              required
            />
          </Grid>
          <Grid item mobile={6}>
            <TextField
              id="terminalAddressId"
              name="terminalAddressId"
              label={t`Address ID`}
              value={values.terminalAddressId}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalAddressId &&
                (Boolean(errors.terminalAddressId) ||
                  Boolean(errors.formErrorField))
              }
              helperText={touched.terminalAddressId && errors.terminalAddressId}
              sx={styles.input}
              disabled
              fullWidth
              required
            />
          </Grid>
          <Grid item mobile={2}>
            <TextField
              id="terminalPostalCode"
              name="terminalPostalCode"
              label={t`Postal code`}
              value={values.terminalPostalCode}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalPostalCode &&
                (Boolean(errors.terminalPostalCode) ||
                  Boolean(errors.formErrorField))
              }
              helperText={
                touched.terminalPostalCode && errors.terminalPostalCode
              }
              sx={styles.input}
              disabled
              fullWidth
              required
            />
          </Grid>
          <Grid item mobile={4}>
            <TextField
              id="terminalCity"
              name="terminalCity"
              label={t`City`}
              value={values.terminalCity}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalCity &&
                (Boolean(errors.terminalCity) || Boolean(errors.formErrorField))
              }
              helperText={touched.terminalCity && errors.terminalCity}
              sx={styles.input}
              disabled
              fullWidth
              required
            />
          </Grid>
          <Grid item mobile={3}>
            <TextField
              id="terminalLatitude"
              name="terminalLatitude"
              label={t`Latitude`}
              type="number"
              value={values.terminalLatitude}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalLatitude &&
                (Boolean(errors.terminalLatitude) ||
                  Boolean(errors.formErrorField))
              }
              helperText={touched.terminalLatitude && errors.terminalLatitude}
              sx={styles.input}
              disabled
              fullWidth
              required
            />
          </Grid>
          <Grid item mobile={3}>
            <TextField
              id="terminalLongitude"
              name="terminalLongitude"
              label={t`Longitude`}
              type="number"
              value={values.terminalLongitude}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalLongitude &&
                (Boolean(errors.terminalLongitude) ||
                  Boolean(errors.formErrorField))
              }
              helperText={touched.terminalLongitude && errors.terminalLongitude}
              sx={styles.input}
              disabled
              fullWidth
              required
            />
          </Grid>
          <Grid item mobile={6}>
            <TextField
              id="terminalState"
              name="terminalState"
              label={t`State`}
              value={values.terminalState}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalState &&
                (Boolean(errors.terminalState) ||
                  Boolean(errors.formErrorField))
              }
              helperText={touched.terminalState && errors.terminalState}
              sx={styles.input}
              disabled
              fullWidth
              // required
            />
          </Grid>
          <Grid item mobile={6}>
            <TextField
              id="addressLine2"
              name="addressLine2"
              label={t`Address line 2`}
              value={values.addressLine2}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.addressLine2 &&
                (Boolean(errors.addressLine2) || Boolean(errors.formErrorField))
              }
              helperText={touched.addressLine2 && errors.addressLine2}
              sx={styles.input}
              // disabled
              fullWidth
            />
          </Grid>
          <Grid item mobile={6}>
            <TextField
              id="terminalCountry"
              name="terminalCountry"
              label={t`Country`}
              value={values.terminalCountry}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.terminalCountry &&
                (Boolean(errors.terminalCountry) ||
                  Boolean(errors.formErrorField))
              }
              helperText={touched.terminalCountry && errors.terminalCountry}
              sx={styles.input}
              disabled
              fullWidth
              required
            />
          </Grid>
        </Grid>
      </Section>
      <Box sx={styles.bottomButtonContainer}>
        <Button
          onClick={() => navigateWithQueryParams(APP_ROUTES.root)}
          sx={styles.cancelButton}
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          sx={styles.button}
          disabled={isSubmitting || !isValid}
          variant="contained"
          type="submit"
        >
          Save
        </Button>
      </Box>
    </FormControl>
  );
};
