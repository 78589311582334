import { FC, useCallback, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Field } from 'formik';
import { useChangeScreenContext } from 'hooks/useChangeScreenContext';
import { useEditScreenErrandTypes } from 'hooks/useChangeScreenErrandTypes';
import { useAllHubsQuery } from 'store/api/apiSlice';
import { t } from 'ttag';

import { AddressSearch } from 'components/common/AddressSearch';
import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldAutocomplete } from 'components/common/FieldAutocomplete';
import { FieldInput } from 'components/common/FieldInput';

export const DeliveryDataTabAddressSection: FC = () => {
  const { formik } = useChangeScreenContext();
  const { values, setValues } = formik;

  const { data: hubs } = useAllHubsQuery();
  const { terminalDeliveryType } = useEditScreenErrandTypes();

  const terminalList = useMemo(() => {
    if (!hubs) {
      return [];
    }
    return hubs.map(({ id, hub_name, hub_code }) => ({
      value: id,
      label: `${hub_code} ${hub_name}`,
    }));
  }, [hubs]);

  useEffect(() => {
    if (!values.deliveryTerminalId && terminalDeliveryType) {
      setValues({
        ...values,
        deliveryAddressId: null,
        deliveryCity: null,
        deliveryCountry: null,
        deliveryLatitude: null,
        deliveryLongitude: null,
        deliveryPostalCode: null,
        deliveryStreet: null,
        deliveryStreetNumber: null,
        deliveryState: null,
        deliveryTerminalName: null,
        deliveryPhoneNumber: null,
        deliveryAddressLine2: null,
      });
    }
  }, []);

  const handleAutocompleteChange = useCallback(
    (e: any, val: { value: string; label: string }) => {
      if (val?.value) {
        const currentTerminal = hubs?.find(({ id }) => id === val?.value);

        if (currentTerminal) {
          setValues({
            ...values,
            deliveryTerminalId: val.value,
            deliveryAddressId: currentTerminal?.address_id,
            deliveryCity: currentTerminal?.data?.hub_city,
            deliveryCountry: currentTerminal?.data?.country,
            deliveryLatitude: currentTerminal?.latitude,
            deliveryLongitude: currentTerminal?.longitude,
            deliveryPostalCode: currentTerminal?.data?.hub_ZIP,
            deliveryStreet: currentTerminal?.data?.hub_street,
            deliveryStreetNumber: currentTerminal?.data?.hub_street_no,
            deliveryTerminalName: currentTerminal?.hub_name,
            deliveryPhoneNumber: currentTerminal?.data?.hub_phone,
            deliveryAddressLine2: currentTerminal?.data?.hub_adr_line_2,
            deliveryState: null,
          });
        } else {
          setValues({
            ...values,
            deliveryAddressId: null,
            deliveryCity: null,
            deliveryCountry: null,
            deliveryLatitude: null,
            deliveryLongitude: null,
            deliveryPostalCode: null,
            deliveryStreet: null,
            deliveryStreetNumber: null,
            deliveryState: null,
            deliveryTerminalName: null,
            deliveryPhoneNumber: null,
            deliveryAddressLine2: null,
          });
        }
      }
    },
    [values, setValues, hubs],
  );

  return (
    <ChangeScreenSection label={t`Address`}>
      <Grid container columns={12} spacing={2} rowGap={4}>
        <Grid item mobile={6}>
          {terminalDeliveryType ? (
            <Field
              component={FieldAutocomplete}
              options={terminalList}
              name="deliveryTerminalId"
              label={t`Terminal name`}
              onChange={handleAutocompleteChange}
              placeholder={t`Enter terminal name or code`}
            />
          ) : (
            <AddressSearch formik={formik} type="delivery" />
          )}
        </Grid>
        <Grid item mobile={4}>
          <Field
            component={FieldInput}
            name="deliveryStreet"
            label={t`Street`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={2}>
          <Field
            component={FieldInput}
            name="deliveryStreetNumber"
            label={t`Street number`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={6}>
          <Field
            component={FieldInput}
            name="deliveryAddressId"
            label={t`Address ID`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={2}>
          <Field
            component={FieldInput}
            name="deliveryPostalCode"
            label={t`Postal code`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={4}>
          <Field
            component={FieldInput}
            name="deliveryCity"
            label={t`City`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={3}>
          <Field
            component={FieldInput}
            name="deliveryLatitude"
            label={t`Latitude`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={3}>
          <Field
            component={FieldInput}
            name="deliveryLongitude"
            label={t`Longitude`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={6}>
          <Field
            component={FieldInput}
            name="deliveryState"
            label={t`State`}
            disabled
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={6}>
          <Field
            component={FieldInput}
            name="deliveryAddressLine2"
            label={t`Address line 2`}
            disabled={terminalDeliveryType}
            fullWidth
            // required
          />
        </Grid>
        <Grid item mobile={6}>
          <Field
            component={FieldInput}
            name="deliveryCountry"
            label={t`Country`}
            disabled
            fullWidth
            // required
          />
        </Grid>
      </Grid>
    </ChangeScreenSection>
  );
};
