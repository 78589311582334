import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  icon: {
    color: 'custom.red',
  },
  addressLine2: {
    color: 'custom.black',
    fontSize: '16px',
    lineHeight: '20px',
  },

  addressSubtitle: {
    color: 'custom.black',
    fontSize: '14px',
    lineHeight: '16px',
  },
};
