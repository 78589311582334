import React, { memo, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import DoDisturbOutlinedIcon from '@mui/icons-material/DoDisturbOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Box,
  Collapse,
  Divider,
  Grid,
  IconButton,
  IconButtonProps,
  ListItem,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import temperedGoodsImg from 'assets/images/tempered_goods.png';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { setSelectedOrderId } from 'store/reducers/orderSlice';
import { calculateUnreadMessagesForShipment } from 'store/selectors/chat';
import { getAllRoutesSelectedOrder } from 'store/selectors/order';
import { t } from 'ttag';
import { Order } from 'types/orders';
import { BarcodesScan } from 'types/terminalScanChart';
import { createUniqueId } from 'utils/uniqIdUtils';

import { InnerSectionPaper } from 'components/common/InnerSectionPaper';
import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { UnreadLabel } from 'components/common/UnreadLabel';
import { EditButton } from 'components/ui/EditButton';

import { styles } from './styles';

interface ExpandMoreProps extends IconButtonProps {
  expand: string;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  // const { expand, ...other } = props;
  return <IconButton {...props} />;
})(({ theme }) => ({
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !(expand === 'true'),
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => expand === 'true',
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));

interface DetailsSectionInnerOrdersItemProps {
  order: Order;
  isTerminalReturn?: boolean;
  isTerminal?: boolean;
  barcodes: BarcodesScan | null;
}

export const DetailsSectionInnerOrdersItem = memo(
  (props: DetailsSectionInnerOrdersItemProps) => {
    const {
      order,
      isTerminalReturn = false,
      isTerminal = false,
      barcodes = null,
    } = props;

    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const unreadMessages = useAppSelector(
      calculateUnreadMessagesForShipment(order?.shipmentId),
    );
    const allRoutesSelectedOrder = useAppSelector(getAllRoutesSelectedOrder);

    const isTempered = order?.bookingData?.tempered_transport;
    const imgUrl = order.bookingData?.img_url;

    const barcodesData = useMemo(() => {
      if (!barcodes) {
        return [];
      }

      return Object.entries(barcodes).map(([key, val]) => ({
        barcode: key,
        isScan: val,
      }));
    }, [barcodes]);

    const successBarcodes = useMemo(() => {
      if (!barcodesData.length) {
        return 0;
      }

      return barcodesData.reduce((acc, cur) => {
        if (cur.isScan) {
          return ++acc;
        }

        return acc;
      }, 0);
    }, [barcodesData]);

    const currentBGColor = useMemo(() => {
      if (!isTerminal || !barcodesData.length) {
        return '#FFFFFF';
      }

      if (barcodesData.every(({ isScan }) => isScan)) {
        return 'rgba(66, 161, 70, 0.1)';
      }

      if (barcodesData.some(({ isScan }) => isScan)) {
        return 'rgba(250, 236, 48, 0.18)';
      }

      return '#FFFFFF';
    }, [isTerminal, barcodesData]);

    const paperCustomStyle = useMemo(() => {
      if (!isTerminal) {
        return undefined;
      }

      return {
        paddingBottom: 0,
        backgroundColor: currentBGColor,
      };
    }, [currentBGColor, isTerminal]);

    const clickHandler = useCallback(() => {
      if (allRoutesSelectedOrder) {
        return;
      }
      dispatch(setSelectedOrderId(order.id));
    }, [allRoutesSelectedOrder, dispatch, order]);

    const handleExpandClick = (
      e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      e.stopPropagation();
      setExpanded(!expanded);
    };

    const barcodesContent = useMemo(() => {
      if (!barcodesData.length) {
        return null;
      }

      return barcodesData.map(({ barcode, isScan }) => {
        return (
          <React.Fragment key={createUniqueId()}>
            <Divider color="#E3E3E3" flexItem />
            <Box sx={styles.barcodeItem}>
              {isScan ? (
                <CheckCircleOutlinedIcon sx={{ color: '#42A146' }} />
              ) : (
                <DoDisturbOutlinedIcon sx={{ color: '#666666' }} />
              )}
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '16px',
                  lineHeight: '20px',
                  color: isScan ? '#42A146' : '#666666',
                }}
                ml="16px"
              >
                {barcode}
              </Typography>
            </Box>
          </React.Fragment>
        );
      });
    }, [barcodesData]);

    return (
      <ListItem sx={styles.item} onClick={clickHandler}>
        <InnerSectionPaper customStyles={paperCustomStyle}>
          <Grid container columns={11} sx={styles.content}>
            <Grid item mobile={8} alignSelf="center">
              <Typography variant="h3">
                {order?.[isTerminalReturn ? 'pickAddress' : 'address']?.[
                  'title'
                ] || ''}
              </Typography>
              <Typography sx={styles.addressLine2}>
                {order?.[isTerminalReturn ? 'pickAddress' : 'address']?.[
                  'addressLine2'
                ] || ''}
              </Typography>
              <Typography sx={styles.addressSubtitle}>
                {order?.[isTerminalReturn ? 'pickAddress' : 'address']?.[
                  'subtitle'
                ] || ''}
              </Typography>
              {!isTerminalReturn && <Typography>{order.subtitle}</Typography>}
            </Grid>

            <Grid item mobile={1} alignSelf="center" pl={1}>
              {isTempered && (
                <img src={temperedGoodsImg} alt="Tempered goods" />
              )}
            </Grid>

            {!allRoutesSelectedOrder && (
              <>
                <Grid
                  item
                  mobile={2}
                  alignSelf="center"
                  textAlign="center"
                  pl={1}
                >
                  {!!unreadMessages && (
                    <QueryParamsLink sx={styles.unread}>
                      <UnreadLabel qty={unreadMessages} />
                    </QueryParamsLink>
                  )}
                </Grid>
                {order.isEditable && (
                  <Box sx={styles.editButton}>
                    <EditButton orderId={order.id} />
                  </Box>
                )}
              </>
            )}

            {/* <Grid item mobile={2} alignSelf="center" textAlign="right">*/}
            {/*  {order.isEditable && <EditButton />}*/}
            {/* </Grid>*/}
          </Grid>
          {!isTerminalReturn && (
            <>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider color="#E3E3E3" flexItem sx={styles.divider} />
                <Box sx={styles.parcelsBlock}>
                  <Typography sx={styles.parcels}>
                    {successBarcodes}/{barcodesData.length}{' '}
                    {barcodesData.length > 1 ? t`parcels` : t`parcel`}
                  </Typography>
                  {!!imgUrl && (
                    <Box sx={styles.logoBlock}>
                      <img
                        src={imgUrl}
                        alt={t`Company Logo`}
                        width="100%"
                        style={{ objectFit: 'contain' }}
                      />
                    </Box>
                  )}
                </Box>
                {barcodesContent}
              </Collapse>
              <Divider color="#E3E3E3" flexItem sx={styles.divider} />
              <Box sx={styles.expandButtonBlock}>
                <ExpandMore
                  expand={String(expanded)}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Box>
            </>
          )}
        </InnerSectionPaper>
      </ListItem>
    );
  },
);
