import { memo, useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import { ERRAND_SERVICE_OPTIONS } from 'constants/editOrderOptions';
import { format } from 'date-fns';
import { t } from 'ttag';
import { DedicatedChatShipmentDetail } from 'types/dedicatedChat';
import { formatMillisecondsToTimestamp } from 'utils/timeUtils';

import { styles } from './style';

interface ChatDetailInfoProps {
  shipmentDetail: DedicatedChatShipmentDetail | null;
  eta: string | null;
  pod: string | null;
  margin: number | null;
}
export const ChatDetailInfo = memo((props: ChatDetailInfoProps) => {
  const { shipmentDetail, eta, pod, margin } = props;

  const marginBlockStyle = useMemo(() => {
    if (!margin) {
      return styles.marginBlock;
    }

    if (margin > 0) {
      return {
        ...styles.marginBlock,
        color: 'custom.green',
      };
    }

    return {
      ...styles.marginBlock,
      color: 'custom.red',
    };
  }, [margin]);

  if (!shipmentDetail) {
    return <Typography sx={styles.generalChat}>{t`General chat`}</Typography>;
  }

  return (
    <Grid container columns={12} sx={styles.container}>
      <Grid item mobile={8} sx={styles.addressDescription}>
        {shipmentDetail?.title && (
          <Typography sx={styles.serviceName}>
            {
              ERRAND_SERVICE_OPTIONS.find(
                (option) => option.value === shipmentDetail.title,
              )?.label
            }
          </Typography>
        )}
        {shipmentDetail?.name && (
          <Typography sx={styles.name}>{shipmentDetail?.name}</Typography>
        )}
        {shipmentDetail?.address && (
          <>
            <Typography sx={styles.addressContent}>
              {`${shipmentDetail?.address?.street}, ${shipmentDetail?.address?.street_number}`}
            </Typography>
            {shipmentDetail?.address?.address_line_2 && (
              <Typography sx={styles.addressContent}>
                {`${shipmentDetail?.address?.address_line_2}`}
              </Typography>
            )}
            <Typography sx={styles.addressContent}>
              {`${shipmentDetail?.address?.postal_code}, ${shipmentDetail?.address?.city}`}
            </Typography>
          </>
        )}
      </Grid>
      <Grid item mobile={4} sx={styles.timeBlock}>
        {eta && (
          <>
            <Typography sx={styles.etaPodBlock}>
              {`${pod ? 'POD' : 'ETA'} `}
              <span>{format(new Date(pod ? pod : eta), 'HH:mm')}</span>
            </Typography>
            {margin && (
              <Typography sx={marginBlockStyle}>
                {`${margin > 0 ? '+' : '-'}${formatMillisecondsToTimestamp(
                  margin,
                )}`}
              </Typography>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
});
