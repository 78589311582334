import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  item: {
    padding: '0 15px',
    cursor: 'pointer',

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '34px',
      borderLeft: '1px dashed',
      borderColor: 'custom.greyLight',
    },

    // This code line removes dashed line on the top for the 'icon' of the first item
    '&:first-of-type:after': {
      top: '50%',
    },

    // This code line removes dashed line on the bottom for the 'icon' of the lst item
    '&:last-of-type:after': {
      bottom: '50%',
    },

    '&:hover': {
      bgcolor: 'custom.greyLightBlue',
    },
  },

  selected: {
    bgcolor: 'custom.blueLight',
    '&:hover': {
      bgcolor: 'custom.blueLight',
    },
  },

  assigned: {
    bgcolor: 'custom.main15',
  },

  content: {
    ml: '12px',
    mt: '-1px',
    p: '10px 0',

    borderTop: '1px solid',
    borderColor: 'custom.greyLight',
  },

  reorderButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'grab',
  },

  addressLine2: {
    color: 'custom.black',
    fontSize: '16px',
    lineHeight: '20px',
  },

  addressSubtitle: {
    color: 'custom.black',
    fontSize: '14px',
    lineHeight: '16px',
  },

  editButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },

  moveButton: {
    position: 'absolute',
    right: '16px',
  },

  popup: {
    borderColor: 'grey',
    boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px;',
  },
};
